.address-container {
  position: relative;
  padding-bottom: 52px;
  background-color: #e8e00a;
  margin-bottom: 20px;

  .address-main {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
  }

  .address-context {
    width: calc(100% - 302px);
    background-color: #fff;
    margin: 0 0 0 227px;
    // position: absolute;
    // top: 0;
    // left: 227px;
    border-radius: 24px;
    z-index: 3;
    padding-bottom: 40px;
  }

  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;

    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;

      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .title {
    // margin-top: 39px;
    font-family: "Larish Neue", "思源宋体";
  }

  .title-1 {
    font-size: 36px;
    font-weight: 400;
  }

  .title-2 {
    font-size: 100px;
    line-height: 98px;
    font-weight: 400;
  }

  .title-3 {
    font-size: 48px;
    font-weight: 400;
    line-height: 55px;
  }

  .title-4 {
    font-size: 110px;
    font-weight: 400;
    line-height: 55px;
  }

  .title-5 {
    font-size: 110px;
    font-weight: 400;
    line-height: 118px;
  }

  .title-6 {
    font-size: 32px;
    line-height: 55px;
    font-weight: 400;
    font-family: "Larish Neue", "思源宋体";
  }

  .label-margin {
    margin-top: 16px;
  }

  .describe {
    font-size: 14px;
    line-height: 21px;

    .context-left {
      // margin-right: 50px;
      .title {
        width: 40%;
        max-width: 660px;
      }

      .text-one {
        font-family: "Larish Neue", "思源宋体";
        // font-weight: 600;
        // color: #003593;
      }
    }

  }

  .road {
    display: flex;

    .address-detail {
      margin-left: 40px;
    }
  }

  .page-btn {
    position: absolute;
    bottom: 57px;
    right: 77px;
    background-color: #f3ea00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "Larish Neue", "思源宋体";
    cursor: pointer;

    img {
      width: 8px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #e8e00a;
    }
  }

  .map {
    img {
      width: 100%;
      max-width: 655px;
      // height: 419px;
    }
  }

  .road-map {
    display: flex;

    .connect-img {
      display: flex;
      flex-direction: column;
    }
  }

  .time {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 55px;
    font-family: "Larish Neue", "思源黑体", 'Larish Neue';
    font-weight: 400;

    img {
      width: 32px;
      height: 32px;
      margin-right: 21px;
    }
  }

  .get-there {
    margin-top: 18px;

    .subway {
      display: flex;
      margin-top: 8px;

      img {
        width: 30px;
        height: 29px;
        margin-right: 21px;
      }
    }
  }

  .car-park {
    margin-top: 18px;
    display: flex;

    img {
      width: 33px;
      height: 13px;
      margin-right: 18px;
    }

    .label-margin {
      margin-top: 20px;
    }
  }

  .split-line {
    margin: 0 40px;
    height: 100%;
    border: 1px solid #003593;
  }

  .connect {
    img {
      width: 134px;
      height: 134px;
    }

    img:nth-last-child(1) {
      margin-top: 20px;
    }
  }
}

.word-nowrap {
  white-space: nowrap;
}

.blue-color {
  color: #003593;
}

@media screen and (max-width: 1549px) and (min-width: 1100px) {
  .address-container {
    .road-map {
      flex-direction: column;
    }

    .split-line {
      height: auto;
    }

    .road {
      margin-top: 20px;
    }

    .address-detail {
      margin-left: 0;
    }
  }
}


@media screen and (max-width: 1399px) and (min-width: 1000px) {
  .address-container .describe {}

  .map {
    img {
      width: 100%;
      max-width: 655px;
      min-width: 400px;
      // height: 419px;
    }
  }

  .address-container .describe .context-right {
    margin-top: 52px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1279px) and (min-width: 1001px) {
  .address-container {
    .title{
      width: 21%;
    }
    .title-4,
    .title-5 {
      font-size: 50px;
      line-height: 50px;
    }

    .title-6 {
      font-size: 28px;
      line-height: 38px;
      font-family: "Larish Neue", "思源宋体";
    }
  }
}

@media (max-width: 1099px) {
  .address-container .road {
    flex-direction: column;
  }

  .address-container .road-map {
    flex-direction: column;
  }

  .address-container .road .address-detail {
    margin-left: 0;
    margin-top: 20px;
  }

  .address-container .split-line {
    display: none;
  }

  .address-container .road-map .connect-img {
    flex-direction: row;
    margin-top: 12px;
  }

  .address-container .connect img:nth-last-child(1) {
    margin-top: 0;
    margin-left: 20px;
  }

  .address-container .connect {
    margin-top: 30px;
  }
}

@media (max-width: 1000px) {
  .address-container {
    .address-main {
      padding: 0 8px;
      margin-bottom: 20px;
    }

    .address-context {
      margin: 0;
      width: 100%;
      border-radius: 12px;
    }

    .context {
      margin: 0;
      padding: 19px 16px;

      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;

        .context-header-connecting-line {
          width: 76px;
        }
      }
    }

    .title {
      margin-top: 0;
      img {
        /* width: 76px;
        min-width: 76px;
        max-width: 76px; */
      }
    }

    .title-1 {
      font-size: 18px;
    }

    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
    }

    .title-3 {
      font-size: 24px;
      line-height: 24px;
    }

    .title-4 {
      font-size: 36px;
      line-height: 24px;
    }

    .title-5 {
      font-size: 36px;
      line-height: 40px;
    }

    .title-6 {
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      font-family: "Larish Neue", "思源宋体";
    }

    .label-margin {
      margin-top: 6px;
    }

    .describe {
      font-size: 12px;
      line-height: 18px;

      .context-right {
        margin-top: 16px;
        margin-right: 0;

        .text-one {
          margin-bottom: 20px;
          word-wrap: break-word;
          text-align: justify;
          font-family: "Larish Neue", "思源宋体";
          font-weight: 600;
          color: #003593;
          font-size: 16px;
        }
      }
    }

    .page-btn {
      bottom: 22px;
      right: 22px;
      font-size: 12px;
    }

    .map {
      width: 100%;

      img {
        width: 100%;
        // height: 223px;
      }
    }

    .time {
      display: flex;
      align-items: center;
      font-size: 12px;
      // line-height: 25px;
      font-family: "思源黑体 CN", "Larish Neue";
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .get-there {
      margin-top: 6px;

      .subway {
        margin-top: 6px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 7px;
        }
      }
    }

    .car-park {
      display: flex;
      margin-top: 6px;

      img {
        width: 18px;
        height: 7px;
        margin-right: 5px;
        margin-top: 5px;
      }
    }

    .connect {
      margin-top: 4px;

      img {
        width: 124px;
        height: 124px;
      }
    }
  }
}

@media (max-width: 575px) {
  .address-container .map img {
    width: 100%;
    min-width: 320px;
    max-width: 346px;
  }
}