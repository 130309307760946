.contact-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f3ea00;
  .header-logo1{
    position: absolute;
  }
  .contact-1 {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    z-index: 3;
  }
  .contact-logo1 {
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
    img.logo1 {
      width: 105px;
      object-fit: cover;
    }

    // img.typeface{
    //   width: 76px;
    //   height: 21px;
    // }
  }
  .splitters {
    display: block;
  }
  .contact-main {
    width: 100%;
    height: calc(100% - 161px);
    position: relative;
  }
  .contact-context {
    width: calc(100% - 302px);
    background-color: #fff;
    height: 100%;
    position: absolute;
    bottom: 52px;
    left: 227px;
    border-radius: 24px;
    z-index: 3;
  }
  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;
    height: 100%;
    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;
      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }
  .title {
    // margin-top: 39px;
    font-family: "Larish Neue", "思源宋体";
  }
  .title-1 {
    font-size: 36px;
    font-weight: 400;
    font-family: "Larish Neue", "思源宋体";
  }
  .title-2 {
    font-size: 90px;
    line-height: 98px;
    font-weight: 400;
  }
  .label-margin {
    margin-top: 16px;
  }
  .describe {
    display: flex;
    font-size: 14px;
    line-height: 21px;
    height: 100%;
    .context-left {
      width: 30%;
      animation: visible1 2s ease;
      // max-width: 190px;
    }
    .le-ou {
      width: 70%;
      margin-top: 55px;
    }
    .context-right {
      height: calc(100% - 110px);
      overflow-x: hidden;
      overflow-y: auto;
      // width: 70%;
      /* flex: 1;
      display: flex;
      flex-direction: column; */
      // margin-top: 60px;
      .title1 {
        font-family: "Larish Neue", "思源宋体";
      }
      .text-one {
        margin-bottom: 22px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        color: #003593;
        font-size: 16px;
      }
      #subject,#name,#email,#number,#contactForm {
        font-family: "Larish Neue", "思源宋体";
      }
    }
  }
  .page-btn {
    position: absolute;
    bottom: 57px;
    right: 77px;
    background-color: #f3ea00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    align-items: center;
    visibility: hidden;
    font-family: "Larish Neue", "思源宋体";
    cursor: pointer;
    img {
      width: 8px;
      margin-left: 10px;
    }
    &:hover {
      background-color: #e8e00a;
    }
  }
  .foot-bar {
    display: flex;
    align-items: center;
    padding: 14px 20px;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
    .footer-left {
      margin-right: 71px;
    }
    img {
      width: 17px;
      height: 17px;
      margin: 0 5px 0 17px;
      vertical-align: middle;
    }
  }
  .concat-btn {
    width: 272px;
    height: 64px;
    background-color: #003593;
    text-align: center;
    line-height: 64px;
    color: #f3ea00;
    animation: visible1 2.7s ease;
    border-radius: 8px;
    position: relative;
  }
  .form-arrow-icon{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 12px;
  }
  .from-two {
    width: 50%;
    margin-bottom: 24px;
  }
  .from-one{
    width: 100%;;
    margin-bottom: 20px;
  }
  .mobile-menu-icon{
    display: none;
    width: 32px;
    height: 32px;
  }
  input,select,textarea{
    border-radius: 6px;
    background-color:#fff;
    border-color: #003593;
    color: #003593;
    border-width: 2px;
  }
}
.word-nowrap {
  white-space: nowrap;
}

.blue-color {
  color: #003593;
}
@media (max-width: 1279px) and (min-width: 1000px){
  .contact-container .title-2{
    font-size: 70px;
    line-height: 70px;
  }
}
@media (max-width: 1000px) {
  .contact-container {
    .contact-main {
      min-height: 710px;
      height:calc(100% - 140px);
      padding: 0 0px;
      background-color: #e8e00a;
    }
    .contact-logo1 {
      width: 100%;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img.logo1 {
        width: 78px;
        object-fit: cover;
      }
      .typeface {
        display: none;
      }
    }
    .contact-context {
      height:636px;
      width: 100%;
      left: 0px;
      border-radius: 12px;
      top: -16px;
    }
    .context {
      margin: 0;
      padding: 19px 16px;
      height: 100%;
      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;
        .context-header-connecting-line {
          width: 76px;
        }
      }
     
    }
    .title {
      margin-top: 0;
      img {
        width: 240px;
        min-width: 200px;
        max-width: 240px;
      }
    }
    .title-1 {
      font-size: 18px;
      font-family: "Larish Neue", "思源宋体";
    }
    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
    }
    .label-margin {
      margin-top: 6px;
    }
    .describe {
      font-size: 12px;
      flex-direction: column;
      line-height: 18px;
      height: 100%;
      .context-right {
        height: 100%;
        .text-one {
          margin-bottom: 20px;
          word-wrap: break-word;
          text-align: justify;
          font-family: "Larish Neue", "思源宋体";
          font-weight: 400;
          color: #003593;
          font-size: 16px;
        }
      }
    }
    .page-btn {
      bottom: 22px;
      right: 22px;
    }
    .concat-btn {
      height: 52px;
      width: 100%;
      line-height: 52px;
    }
    .form-arrow-icon{
      width: 6px;
    }
    .foot-bar {
      padding: 7px 20px;
      font-size: 12px;
      background-color: #e8e00a;
      font-size: 12px;
      .footer-left {
        margin-left: 29px;
        margin-right: 0;
      }
      .footer-right {
        display: none;
      }
    }
    .from-two {
      margin-bottom: 12px;
    }
    .mobile-menu-icon{
      display:block;
    }
  }
}
@keyframes visible1 {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  25%{
    opacity: 0;
  }
  40% {
    opacity: 0.3;
    visibility: visible;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}