.arrow-left {
  width: 2%;
  max-width: 35px;
  min-width: 8px;
  height: 35px;
  position: absolute;
  top: 48%;
  left: 28px;
  z-index: 1;
}
.arrow-right {
  width: 2%;
  max-width: 35px;
  min-width: 8px;
  position: absolute;
  top: 48%;
  right: 28px;
  z-index: 1;
}
.swiper-img{
  width: 100%;
  height: 100%;
  object-fit: cover
}
.about-banner{
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 11%;
  }
  .swiper-pagination-bullet {
    border: 1px solid #fff;
    width: 11px;
    height: 11px;
  }
  
  .swiper-pagination-bullet-active{
    background:#fff
  }
}

.banner1-m {
  width: 120px;
  height: 130px;
  position: absolute;
  left: 35%;
  top: 25%;
}

.banner1-j {
  width: 101px;
  height: 137px;
  position: absolute;
  right: 35%;
  bottom: 35%;
}


@media (max-width: 1000px){
    .arrow-left {
      // width: 8px;
      // height: 8px;
      position: absolute;
      top: 50%;
      left: 6px;
      z-index: 1;
    }
    .arrow-right {
      // width: 8px;
      // height: 8px;
      position: absolute;
      top: 50%;
      right: 7px;
    }
    .swiper-img{
      width: 100%;
      height: 100%;
      object-fit: cover
    }
}