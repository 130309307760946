.hotel-container {
  position: relative;
  margin-top: -90px;

  .splitters {
    display: block;
  }

  .hotel-main {
    width: 100%;
    padding-bottom: 40px;
    // height: 494px;
    background-color: #fff;
    position: relative;
    background-color: #e8e00a;
  }

  .hotel-context {
    width: calc(100% - 302px);
    background-color: #fff;
    margin: 0 0 0 227px;
    position: relative;
    // position: absolute;
    // top: -94px;
    // left: 227px;
    border-radius: 24px;
    z-index: 3;
    padding-bottom: 40px;
  }

  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;

    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;

      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .title {
    margin-top: 20px;
    font-family: "Larish Neue", "思源宋体";
    min-height: 77px;
    margin-bottom: 64px;
  }


  .label-margin {
    margin-top: 16px;
  }

  .describe {
    position: relative;
    display: flex;
    font-size: 14px;
    line-height: 21px;
    min-height: 128px;

    .context-left {
      // margin-right: 50px
      width: 40%;
    }

    .context-right {
      margin-top: 83px;
      width: 60%;

      .text-one {
        margin-bottom: 22px;
        word-break: break-all;
        font-family: "思源黑体", "Noto Sans";
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .page-btn {
    position: absolute;
    bottom: 64px;
    right: 0px;
    background-color: #F3EA00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    font-family: "Larish Neue", "思源宋体";

    img {
      width: 8px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #e8e00a;
    }
  }

  .picture {
    width: 100%;
    object-fit: cover;

    img {
      border-radius: 8px;
      // height: 634px;
    }
  }
}

.word-nowrap {
  white-space: nowrap
}

@media (max-width: 1279px) and (min-width: 1001px) {
  .hotel-container .title-2 {
    font-size: 50px;
    line-height: 50px;
  }

  .hotel-container .title img {
    width: 77%;
  }
}

@media (max-width: 1000px) {
  .hotel-container {
    margin-top: -50px;

    .hotel-main {
      // height: 442px;
      padding: 0 8px;
      padding-bottom: 20px;
      background-color: #e8e00a;
    }

    .hotel-logo1 {
      width: 100%;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img.logo1 {
        width: 78px;
        object-fit: cover;
      }

      .typeface {
        display: none;
      }
    }

    .hotel-context {
      margin: 0;
      width: 100%;
      // left: 8px;
      border-radius: 12px;
      // top:-60px;
      padding-bottom: 0;
    }

    .context {
      margin: 0;
      padding: 19px 16px;

      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;

        .context-header-connecting-line {
          width: 76px;
        }
      }
    }

    .title {
      min-height: 77px;
      margin-top: 0;
      margin-bottom: 62px
    }

    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
    }

    .label-margin {
      margin-top: 6px;
    }

    .describe {
      font-size: 12px;
      flex-direction: column;
      line-height: 18px;

      .context-left {
        margin-right: 0px
      }

      .context-right {
        margin-top: 16px;

        .text-one {
          margin-bottom: 20px;
          word-break: break-all;
          font-family: "思源黑体", "Noto Sans";
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    .page-btn {
      bottom: 22px;
      right: 0px;
      font-size: 12px;
    }

    .picture {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 8px;

      img {
        // height: 634px;
        transition: all .3s ease .1s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media (max-width: 1000px) and (min-width: 470px) {
  .hotel-container {
    .title {
      img {
        // width: 344px;
      }
    }
  }
}
