.top-banner {
    width: 100%;
    height: 100%;
}

.swiper-img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.arrow_down {
    position: absolute;
    left: calc(50% - 15px);
    bottom: 80px;
    z-index: 8;
}

// 轮播右侧 卡图
@media screen and (min-width: 1280px) {
    #index-page {
        .swiper-pagination {
            text-align: left !important;
        }
        .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
            top: 70% !important; 
            left: 72px !important;
            width: '' !important;
        }
        .swiper-pagination-bullet {
            border: 1px solid #fff;
            width: 11px;
            height: 11px;
        }
        .swiper-pagination-bullet-active {
            background-color: #fff;
        }
    }
    .ba_r_img {
        width: 602px;
        height: 370px;
        position: absolute;
        right: 40px;
        bottom: 180px;
    }  
}
@media screen and (min-width: 350px) and (max-width: 1279px) {
    #index-page {
        .swiper-pagination {
            text-align: left !important;
        }
        .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
            bottom: 155px !important; 
            left: 38% !important;
            width: '' !important;
        }
        .swiper-pagination-bullet {
            border: 1px solid #fff;
        }
        .swiper-pagination-bullet-active {
            background-color: #fff;
        }
    }
    .ba_r_img {
        width: 279px;
        height: 209px;
        position: absolute;
        right: 0px;
        bottom: 180px;
    }  
}
