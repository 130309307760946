.office-container {
  position: relative;
  margin-bottom: -90px;

  .splitters {
    display: block;
  }

  .office-main {
    width: 100%;
    // height: 588px;
    background-color: #fff;
    position: relative;
  }

  .office-context {
    width: calc(100% - 302px);
    background-color: #fff;
    position: relative;
    margin: 0 0 0 227px;
    // position: absolute;
    // top: 0;
    // left: 227px;
    border-radius: 24px;
    z-index: 3;
    min-height: 664px;
  }

  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;

    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;

      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .title {
    // margin-top: 39px;
    font-family: "Larish Neue", "思源宋体";
  }

  .title-1 {
    font-size: 36px;
    font-weight: 400;
  }

  .title-2 {
    font-size: 100px;
    line-height: 98px;
    font-weight: 400;

    img {
      min-width: 400px;
    }
  }

  .label-margin {
    margin-top: 16px;
  }

  .describe {
    display: flex;
    font-size: 14px;
    line-height: 21px;

    .context-left {
      // margin-right: 50px;
      width: 40%;
      max-width: 660px;
    }

    .context-right {
      width: 60%;
      .nullimg {
        display: block;
      }

      .text-one {
        margin-bottom: 22px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        // color: #003593;
        font-size: 16px;
      }
    }
  }

  .page-btn {
    /* position: absolute;
    bottom: 57px;
    right: 56px; */
    margin: 30px 0;
    background-color: #f3ea00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "Larish Neue", "思源宋体";
    cursor: pointer;

    img {
      width: 8px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #e8e00a;
    }
  }

  .arrow-down {
    position: absolute;
    bottom: 18px;
    left: calc(50% - 15px);
    width: 30px;
    animation: flash 1.5s infinite;
  }
  @keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
  }

  .office-right {
    // display: flex;
    // margin-top: 102px;

    .text-one {
      margin-top: 0 !important;
      width: 100%;
      // margin-right: 8px;
      word-wrap: break-word;
      text-align: justify;
      font-family: "Larish Neue", "思源宋体";
      font-weight: 400;
      // color: #003593;
    }

    .text-two {
      width: 100%;
      // margin-left: 8px;
      word-wrap: break-word;
      text-align: justify;
      font-family: "Larish Neue", "思源宋体";
      font-weight: 400;
      // color: #003593;
      font-size: 16px;
    }
  }
}

.word-nowrap {
  white-space: nowrap;
}

@media (max-width: 1279px) and (min-width: 1000px) {
  .office-container{
    .title{
      width: 100%;
    }
    .context-left {
      width: 40%;
    }
    .context-right {
      width: 60%;
    }
  }
  .office-container .title-2 img {
    min-width: 120px;
    max-width: 180px;
  }

  .office-container .title-1 {
    line-height: 28px;
    font-size: 17px;
  }
  .office-container .title{
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .office-container {
    margin-bottom: -50px;

    .office-main {
      // height: 530px;
    }

    .office-context {
      width: 100%;
      margin: 0;
      // left: 8px;
      border-radius: 12px;
      min-height: 612px;
      position: relative;
    }

    .context {
      margin: 0;
      padding: 19px 16px;

      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;

        .context-header-connecting-line {
          width: 76px;
        }
      }
    }

    .title {
      width: 100%;
      margin-top: 0;
      img {
        /* width: 147px;
        min-width: 147px;
        max-width: 147px; */
      }
    }

    .title-1 {
      font-size: 18px;
    }

    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;

      img {
        min-width: 147px;
        max-width: 147px;
      }
    }

    .label-margin {
      margin-top: 6px;
    }

    .describe {
      font-size: 12px;
      flex-direction: column;
      line-height: 18px;

      .context-right {
        margin-top: 16px;
        width: 100%;
        .nullimg {
          display: none;
        }

        .text-one {
          margin-bottom: 20px;
          word-wrap: break-word;
          text-align: justify;
          font-family: "Larish Neue", "思源宋体";
          font-weight: 400;
          // color: #003593;
        }
      }
    }

    .page-btn {
      bottom: 22px;
      right: 22px;
      font-size: 12px;
    }

    .arrow-down {
      display: none;
    }

    .office-right {
      flex-direction: column;
      margin-top: 12px;

      .text-one {
        width: 100%;
        margin-right: 0px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        // color: #003593;
      }

      .text-two {
        width: 100%;
        margin-left: 0px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        // color: #003593;
        font-size: 16px;
      }
    }
  }
}
