.header-logo1 {
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  .typeface{
    display: flex;
    align-items: center;
    font-family: '思源黑体',"Larish Neue", ;
    color: #ffffff87;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  img.logo1 {
    width: 105px;
    object-fit: cover;
  }
  .active{
    color: #fff;
  }
}


.mobile-menu-icon{
  display: none;
  width: 32px;
  height: 32px;
}


@media (max-width: 1000px) {
  .header-logo1 {
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img.logo1 {
      width: 78px;
      object-fit: cover;
    }
    .typeface {
      display: none;
      font-size: 12px;
    }
  }
  .mobile-menu-icon{
    display:block;
  }
}


