.jakcMall-container {
  position: relative;
  margin-top: -90px;

  .jakcMall-main {
    width: 100%;
    background-color: #f3ea00;
    position: relative;
    padding-bottom: 40px;
  }

  .jakcMall-context {
    width: calc(100% - 302px);
    background-color: #fff;
    position: relative;
    margin: 0 0 0 227px;
    // position: absolute;
    // top: -90px;
    // left: 227px;
    border-radius: 24px;
    z-index: 3;
  }

  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;

    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;

      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .title {
    // margin-top: 39px;
    font-family: "Larish Neue", "思源宋体";
  }

  .title-1 {
    font-size: 36px;
    font-weight: 400;

    .jakc-mall-content-jack {
      max-width: 80px;
    }

    .jack-mall-content-brand {
      margin-top: 24px;
      min-width: 320px;
      max-width: 320px;
    }
  }

  .title-2 {
    font-size: 100px;
    line-height: 98px;
    font-weight: 400;
    min-width: 120px;
    max-width: 180px;
  }

  .describe {
    display: flex;
    font-size: 14px;
    line-height: 21px;

    .context-left {
      // margin-right: 50px;
      width: 40%;
      max-width: 660px;
    }

    .context-right {
      // margin-top: 40px;
      width: 60%;
      .nullimg {
        display: block;
      }

      .text-one {
        margin-bottom: 22px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        // color: #003593;
        font-size: 16px;
      }
    }
  }

  .page-btn {
    position: absolute;
    bottom: 57px;
    right: 77px;
    background-color: #f3ea00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "Larish Neue", "思源宋体";
    cursor: pointer;

    img {
      width: 8px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #e8e00a;
    }
  }

  .brand-list {
    padding-bottom: 40px;

    .brand-item {
      width: 7%;
      margin: 25px 3.5%;
    }
  }
}

.word-nowrap {
  white-space: nowrap;
}

@media (max-width: 1279px) and (min-width: 1000px) {
  .jakcMall-container {
    .title {
      width: 100%;
    }

    .context-left {
      width: 40%;
    }

    .context-right {
      width: 60%;
    }
  }

  .jakc-mall-content-jack {
    width: 60px;
  }

  .jakcMall-container .title-1 .jack-mall-content-brand {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1000px) {
  .jakcMall-container {
    margin-top: -50px;

    .jakcMall-main {
      padding: 0 8px;
      padding-bottom: 20px;
    }

    .jakcMall-context {
      width: 100%;
      margin: 0;
      border-radius: 12px;
    }

    .context {
      margin: 0;
      padding: 19px 16px;

      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;

        img {
          width: 385px;
        }

        .context-header-connecting-line {
          width: 76px;
        }
      }
    }

    .title {
      margin-top: 0;
      width: 100%;
      img {
        /* width: 147px;
        min-width: 147px;
        max-width: 147px; */
      }
    }

    .title-1 {
      font-size: 18px;
      display: flex;
      flex-direction: column;

      .jack-mall-content-brand {
        width: 147px;
        margin-top: 12px;
        max-width: 147px;
        min-width: 147px;
      }

      .jakc-mall-content-jack {
        width: 47px;
      }
    }

    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
    }

    .label-margin {
      margin-top: 6px;
    }

    .describe {
      font-size: 12px;
      flex-direction: column;
      line-height: 18px;

      .context-right {
        margin-top: 16px;
        width: 100%;
        .nullimg {
          display: none;
        }

        .text-one {
          margin-bottom: 20px;
          word-wrap: break-word;
          text-align: justify;
          font-family: "Larish Neue", "思源宋体";
          font-weight: 400;
          // color: #003593;
          font-size: 16px;
        }
      }
    }

    .page-btn {
      bottom: 22px;
      right: 22px;
      font-size: 12px;
    }

    .brand-list {
      padding-bottom: 0px;

      .brand-item {
        width: 7%;
        margin: 10px 3.5%;
      }
    }
  }
}

.active {
  // transform: translateY(-90px);
  // z-index: 2;
  // transition: all 2s;
}

.swiper-img{
  width: 100%;
  height: 100%;
  object-fit: cover
}