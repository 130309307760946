.residence-container {
  position: relative;
  margin: -90px 0;

  .residence-1 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    // background-size: 100% 100%;
    z-index: 4;
    visibility: hidden;
    object-fit: cover;
  }

  .residence-logo1 {
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
    position: fixed;

    img.logo1 {
      width: 105px;
      object-fit: cover;
    }

    // img.typeface{
    //   width: 76px;
    //   height: 21px;
    // }
  }

  .splitters {
    display: block;
    width: 100%;
  }

  .residence-main {
    width: 100%;
    background-color: #fff;
    position: relative;
  }

  .residence-context {
    width: calc(100% - 302px);
    background-color: #fff;
    height: 634px;
    margin: 0 0 0 227px;
    position: relative;
    // position: absolute;
    // top: -55px;
    // left: 227px;
    border-radius: 24px;
    z-index: 3;
  }

  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;

    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;

      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .title {
    // margin-top: 39px;
    font-family: "Larish Neue", "思源宋体";
  }

  .title-1 {
    font-size: 36px;
    font-weight: 400;
  }

  .title-2 {
    font-size: 100px;
    line-height: 98px;
    font-weight: 400;

    img {
      min-width: 404px;
      max-width: 404px;
    }
  }

  .label-margin {
    margin-top: 16px;
  }

  .describe {
    display: flex;
    font-size: 14px;
    line-height: 21px;

    .context-left {
      // margin-right: 50px;
      width: 40%;
      max-width: 660px;
    }

    .context-right {
      // margin-top: 83px;
      width: 60%;
      .nullimg {
        display: block;
      }

      .text-one {
        margin-bottom: 22px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        // color: #003593;
        font-size: 16px;
      }

      .text-two {
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        // color: #003593;
        font-size: 16px;
      }
    }
  }

  .page-btn {
    /* position: absolute;
    bottom: 57px;
    right: 77px; */
    margin: 30px 0;
    background-color: #f3ea00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    justify-content: center;
    align-items: center;
    // visibility: hidden;
    font-size: 14px;
    cursor: pointer;
    font-family: "Larish Neue", "思源宋体";

    img {
      width: 8px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #e8e00a;
    }
  }

  .arrow-down {
    position: absolute;
    bottom: 18px;
    left: calc(50% - 15px);
    width: 30px;
    animation: flash 1.5s infinite;

  }
  @keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
  }

  .mobile-menu-icon {
    display: none;
    width: 32px;
    height: 32px;
  }
}

.word-nowrap {
  white-space: nowrap;
}

@media (max-width: 1279px) and (min-width: 1000px) {
  .residence-container {
    .title{
      width: 100%;
    }
    .context-left {
      width: 40%;
    }
    .context-right {
      width: 60%;
    }
    .title-2 {
      img {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .residence-container {
    .residence-main {
      padding: 0 8px;
    }
    .residence-logo1 {
      width: 100%;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img.logo1 {
        width: 78px;
        object-fit: cover;
      }
      .typeface {
        display: none;
      }
    }
    .residence-context {
      min-height: 498px;
      width: 100%;
      border-radius: 12px;
    }
    .context {
      margin: 0;
      padding: 19px 16px;
      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;
        .context-header-connecting-line {
          width: 76px;
        }
      }
    }
    .title {
      margin-top: 0;
      width:100% ;
      img {
        /* width: 147px;
        min-width: 147px;
        max-width: 147px; */
      }
    }
    .title-1 {
      font-size: 18px;
    }
    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;
      img{
        width: 147px;
        min-width: 147px;
        max-width: 147px;
      }
      .context-right{
        // margin-top: 83px;
        width: 100%;
        .nullimg {
          display: block;
        }
        .text-one{
          margin-bottom:   22px;
          word-wrap: break-word;
          text-align: justify;
          font-family: "Larish Neue", "思源宋体";
          font-weight: 400;
          // color: #003593;
          font-size: 16px;
        }
      }
    }
    .page-btn{
      /* position: absolute;
      bottom: 64px;
      right: 0px; */
      margin: 30px 0;
      background-color:#F3EA00 ;
      padding: 8px 16px;
      border-radius: 8px;
      color: #003593;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: "Larish Neue", "思源宋体";
      cursor: pointer;
      img{
        width: 8px;
        margin-left: 10px;
      }
      &:hover{
        background-color:#e8e00a ;
      }
    }
    .label-margin {
      margin-top: 6px;
    }
    .describe {
      font-size: 12px;
      flex-direction: column;
      line-height: 18px;
      .context-right {
        margin-top: 16px;
        .nullimg {
          display: none;
        }
        .text-one {
          margin-bottom: 20px;
          word-break: break-all;
          font-family: "Larish Neue", "思源宋体";
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .page-btn {
      bottom: 22px;
      right: 22px;
      font-size: 12px;
    }
    .arrow-down{
      display: none;
    }
    .mobile-menu-icon{
      display:block;
    }
  }
}


  @media (max-width: 1000px) {
    .residence-container {
      margin: -50px 0;
      .residence-main {
        padding: 0 8px;
        background-color: #e8e00a;
      }

      .residence-logo1 {
        width: 100%;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img.logo1 {
          width: 78px;
          object-fit: cover;
        }

        .typeface {
          display: none;
        }
      }

      .residence-context {
        margin: 0;
        width: 100%;
        border-radius: 12px;
        padding-bottom: 0;
      }

      .context {
        margin: 0;
        padding: 19px 16px;

        .context-header {
          font-family: "Larish Neue", "思源宋体";
          padding-top: 4px;
          font-size: 6px;
          border-top: 1px solid #020202;

          .context-header-connecting-line {
            width: 76px;
          }
        }
      }

      .title {
        min-height: 77px;
        margin-top: 0;
        margin-bottom: 62px;
      }

      .title-2 {
        font-size: 36px;
        line-height: 36px;
        font-weight: 400;
      }

      .label-margin {
        margin-top: 6px;
      }

      .describe {
        font-size: 12px;
        flex-direction: column;
        line-height: 18px;

        .context-left {
          margin-right: 0px
        }

        .context-right {
          margin-top: 16px;
          width: 100%;
          .nullimg {
            display: none;
          }

          .text-one {
            margin-bottom: 20px;
            word-wrap: break-word;
            text-align: justify;
            font-family: "Larish Neue", "思源宋体";
            font-weight: 400;
            // color: #003593;
            font-size: 16px;
          }
        }
      }

      .page-btn {
        bottom: 22px;
        right: 16px;
        font-size: 12px;
      }

      .picture {
        width: 100%;

        img {
          border-radius: 8px;
          // height: 226px;
        }
      }
    }
  }
