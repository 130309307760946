.foot-bar {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 3;
  font-size: 12px;
  .text-one {
    font-family: "Larish Neue", "思源宋体";
  }
  .footer-left{
  }
  .footer-right{
    display: flex;
    align-items: center;
  }
  img{
    width:17px ;
    height: 17px;
    margin: 0 5px 0 17px;
    vertical-align: middle;
  }
}
.word-nowrap {
  white-space: nowrap;
}
.blue-color {
  color: #003593;
}
@media (max-width: 1000px) {
    .foot-bar {
      padding: 7px 20px;
      font-size: 12px;
      background-color: #e8e00a;
      .footer-left{
      }
      .footer-right{
        display: none;
      }
    }
}
