// Plugin CSS
@import "~animate.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/thumbs";
@import "swiper/scss/free-mode";
@import "swiper/css/effect-fade";
@import "rc-slider/assets/index.css";

// Init main SCSS File
@import "/public/assets/css/demo.scss";
@import "/public/assets/sass/style.scss";


@font-face {
    font-family: 'Larish Neue';
    src: url('../public/assets/fonts/LarishNeue.ttf'); /* 注意路径 */
}

@font-face {
    font-family: '思源宋体';
    src: url('../public/assets/fonts/SourceHanSerifCN-Bold.otf'); /* 注意路径 */
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../public/assets/fonts/NotoSans-Regular.ttf');
}

/* @font-face {
    font-family: '思源黑体';
    src: url('../public/assets/fonts/SourceHanSerifTW-SemiBold.otf');
} */
  