* {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0px;
  padding-left: 0;
}
ul,
li {
  padding-left: 0;
}
#about-page {
  .menu-container {
    left: 40px;
    /* backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 15px;
    border-radius: 4px; */
  }
}
.menu-container {
  position: fixed;
  left: 72px;
  top: 30%;
  // visibility: hidden;
  z-index: 3;
  color: #000;
  .menu-1-style {
    font-size: 30px;
    font-weight: 400;
    font-family: "Larish Neue", "思源宋体";
    margin-bottom: 30px;
    cursor: pointer;
  }
  .menu-2-style {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 8px;
    margin-bottom: 4px;
    cursor: pointer;
  }
  .sub-menu {
    padding-left: 17px;
    border-left: 1px solid #003593;
    margin-top: 15px;
  }
}
@media (max-width: 1000px) {
  .menu-container {
    display: none !important;
  }
}
@keyframes visible1 {
  0% {
    display: none;
    // visibility: hidden;
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
    display: block;
    // visibility: visible;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hidden1 {
  0% {
    // visibility: visible;
    opacity: 1;
  }
  40% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.3;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.menu-hidden {
  display: none;
  // .menu-container {
  //   visibility: hidden;
  //   animation: hidden1 2s linear;
  // }
}
.menu-show {
 .menu-container {
    // display: block;
    animation: visible1 2s linear;
  }
}


