.about-container {
  position: relative;

  .about-1 {
    width: 100%;
    height: 161px;
    position: fixed;
    top: 0;
    left: 0;
    // background-size: 100% 100%;
    z-index: 4;
    visibility: hidden;
    object-fit: cover;
  }

  .about-header {
    width: 100%;
    height: 161px;
  }

  .about-logo1 {
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
    position: fixed;

    img.logo1 {
      width: 105px;
      object-fit: cover;
    }

    // img.typeface{
    //   width: 76px;
    //   height: 21px;
    // }
  }

  .splitters {
    display: block;
    width: 100%;
  }

  .about-main {
    width: 100%;
    height: 494px;
    background-color: #fff;
    position: relative;
  }

  .about-context {
    width: calc(100% - 302px);
    background-color: #fff;
    height: 634px;
    position: absolute;
    top: 80px;
    left: 227px;
    border-radius: 24px;
    z-index: 3;
  }

  .context {
    margin: 0 55px 0 134px;
    padding-top: 46px;
    position: relative;

    .context-header {
      font-family: "Larish Neue", "思源宋体";
      width: 100%;
      border-top: 2px solid #020202;
      text-align: right;
      padding-top: 16px;
      font-size: 11px;

      .context-header-connecting-line {
        display: inline-block;
        width: 153px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .title {
    // margin-top: 39px;
    font-family: "Larish Neue", "思源宋体";
    // width: 460px;
  }

  .label-margin {
    margin-top: 16px;
  }

  .describe {
    display: flex;
    font-size: 14px;
    line-height: 21px;

    .context-left {
      // margin-right: 50px;
      width: 40%;
      max-width: 660px;
    }

    .context-right {
      // margin-top: 83px;
      width: 60%;
      .nullimg {
        display: block;
      }

      .text-one {
        margin-bottom: 22px;
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        // color: #003593;
        font-size: 16px;
      }

      .text-two {
        word-wrap: break-word;
        text-align: justify;
        font-family: "Larish Neue", "思源宋体";
        font-weight: 400;
        // color: #003593;
        font-size: 16px;
      }
    }
  }

  .page-btn {
    /* position: absolute;
    bottom: 57px;
    right: 77px; */
    margin: 30px 0;
    background-color: #f3ea00;
    padding: 8px 16px;
    border-radius: 8px;
    color: #003593;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    font-size: 14px;
    font-family: "Larish Neue", "思源宋体";
    cursor: pointer;

    img {
      width: 8px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #e8e00a;
    }
  }

  .arrow-down {
    position: absolute;
    bottom: 18px;
    left: calc(50% - 15px);
    width: 30px;
    animation: flash 1.5s infinite;
  }

  @keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

  .mobile-menu-icon {
    display: none;
    width: 32px;
    height: 32px;
  }
}

.word-nowrap {
  white-space: nowrap;
}

.about-animation {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  .about-1 {
    visibility: visible;
    transition: all 1.5s linear 1.5s;
  }

  .about-context {
    top: 0;
    transition: all 1s linear 1.5s;
  }
}
.scroll-item {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

@media (max-width: 1279px) and (min-width: 1000px) {
  .about-container {
    .context-left {
      width: 40%;
    }

    .context-right {
      width: 60%;
    }
    .title {
      width: 100%;
    }
    .title-1 {
      font-size: 20px;
    }

    .title-2 {
      img {
        min-width: 120px;
        max-width: 180px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .about-container {
    .about-header {
      height: 140px;
    }

    .about-1 {
      height: 140px;
    }

    .about-main {
      height: 442px;
      padding: 0 8px;
    }

    .about-logo1 {
      width: 100%;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img.logo1 {
        width: 78px;
        object-fit: cover;
      }

      .typeface {
        display: none;
      }
    }

    .about-context {
      min-height: 498px;
      width: calc(100% - 16px);
      left: 8px;
      border-radius: 12px;
    }

    .context {
      margin: 0;
      padding: 19px 16px;

      .context-header {
        font-family: "Larish Neue", "思源宋体";
        padding-top: 4px;
        font-size: 6px;
        border-top: 1px solid #020202;

        .context-header-connecting-line {
          width: 76px;
        }
      }
    }

    .title {
      margin-top: 0;
      width: 100%;
      img {
        /* width: 147px;
        min-width: 147px;
        max-width: 147px; */
      }
    }

    .title-1 {
      font-size: 18px;
    }

    .title-2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 400;

      img {
        width: 147px;
        min-width: 147px;
        max-width: 147px;
      }
    }

    .label-margin {
      margin-top: 6px;
    }

    .describe {
      font-size: 12px;
      flex-direction: column;
      line-height: 18px;

      .context-right {
        margin-top: 16px;
        width: 100%;
        .nullimg {
          display: none;
        }

        .text-one {
          margin-bottom: 20px;
          word-wrap: break-word;
          text-align: justify;
          font-family: "Larish Neue", "思源宋体";
          // font-weight: 600;
          // color: #003593;
          font-size: 16px;
        }
      }
    }

    .page-btn {
      bottom: 22px;
      right: 22px;
      font-size: 12px;
    }

    .arrow-down {
      display: none;
    }

    .mobile-menu-icon {
      display: block;
    }
  }
}

.about-page {

  position: relative;
  height: 100vh;

  .out-box {
    position: absolute;
    left: 0;
    top: 1000px;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
}



.page1 {
  .out-box {
    top: 0;
    transition: all 2s;
    transition-delay: 0s;
  }
  // .menu-container {
  //   visibility: visible;
  //   animation: visible1 7s linear;
  // }
  .about-container .page-btn {
    visibility: visible;
    animation: visible2 5.5s ease;
  }
}
@keyframes visible1 {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  40% {
    opacity: 0.3;
    visibility: visible;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes visible2 {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  40% {
    opacity: 0.5;
    visibility: visible;
  }

  55% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}