.menu-pop-up-layer{
  padding: 20px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color:#f0e717d9 ;
  z-index: 5;
  .close-icon{
    width: 32px;
    height: 32px;
    margin-top: 12px;
   position: absolute;
   right: 20px;
   top: 20px;
  }
}
.menu-pop-main{
  margin-top: 50px;
  height: 100%;
  overflow-y: auto;
  .menu-icon{
    width: 24px;
  }
  .menu-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-title-1{
    line-height: 52px;
    font-size: 32px;
    font-weight: 700;
    font-family:"Larish Neue", "思源宋体" ;
    padding: 12px 0;
    border-bottom: 4px solid #fff;
  }
  .menu-active{
    color:#003593 ;
  }
  .menu-title-2{
    font-weight: 700;
    font-family:"Larish Neue", "思源宋体" ;
    line-height: 26px;
    padding: 12px 0 0;
    cursor: pointer;
  }
}